import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { environment } from '../environments/environment';
import { TrackerModule } from '@origin8-web/core-ui/tracking';
import { APP_CONFIG, localStorageProvider, windowProvider } from '@origin8-web/core-ui/shared-providers';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import { withApolloGqlProviders } from '@origin8-web/core-ui/graphql';
import { provideServiceWorker } from '@angular/service-worker';
import { withLoginProviders } from '@origin8-web/core-ui/login/providers';
import { ArcClientEnv } from '../environments';
import { GlobalErrorHandler } from '@origin8-web/core-ui/error-handler';

export const appConfig: ApplicationConfig = {
  providers: [
    withLoginProviders(),
    withApolloGqlProviders(),
    importProvidersFrom(TrackerModule.forRoot({ anonymous: false, withGtm: false })),
    localStorageProvider,
    windowProvider,
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules),
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
};

export const getAppConfig = (envVariables: ArcClientEnv) => {
  return { ...appConfig, providers: [...appConfig.providers, { provide: APP_CONFIG, useValue: envVariables }] };
};
