import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface O8TrackingModuleOptions {
  anonymous: boolean;
  withGtm: boolean;
  appId: Observable<string | undefined>;
}

export interface TrackingService {
  getSessionId(): string;
  getRequestId(): string;
  getImpersonationToken(): Observable<string>;
  track(event: string, data?: object): void;
  captureException(error: Error, extra?: object): void;
}
export const TRACKER_SERVICE = new InjectionToken<TrackingService>('trackerService');
export const LOGROCKET = new InjectionToken<any>('LogRocket');
