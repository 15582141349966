import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { UserState } from '../../../../src/app.interface';
import { APP_PERMS, UiUserProfile } from 'common.interfaces';
import { computed } from '@angular/core';

const initialState: UserState = {
  userInfo: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      hasWriteCallReviewPerm: computed(() => {
        return store.userInfo()?.authorizations.permissions.some((p) => p === APP_PERMS.CALL_REVIEW_WRITE) ?? false;
      }),
    };
  }),
  withMethods((store) => {
    return {
      setUserInfo: (userInfo: UiUserProfile | null | undefined) => patchState(store, { userInfo }),
    };
  }),
);
