import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { catchError, first, map, Observable, combineLatest, tap, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  APP_PERMS,
  APP_ROLES,
  FilterComparators,
  O8RootQueryFilter,
  O8User,
  O8UserExtraInfo,
  UiUserProfile,
  SF_REPRESENTATIVE_ROLE_ID,
  SF_STAFF_ID,
  UserProfile,
} from 'common.interfaces';
import { GRAPHQL_CLIENT_LOADER } from '../../graphql-client';
import { getUsersBaseInfo } from './user.query';
import { LOCAL_STORAGE, WINDOW } from '../../../shared-providers';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly userExtraInfo$ = new BehaviorSubject<UserProfile | null>(null);
  private readonly impersonatedUser$ = new BehaviorSubject<UserProfile | null>(null);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly graphqlClientLoader = inject(GRAPHQL_CLIENT_LOADER);
  private readonly localStorage = inject(LOCAL_STORAGE);
  private readonly window = inject(WINDOW);
  private readonly platformId = inject(PLATFORM_ID);

  constructor() {
    this.init().subscribe();
  }

  startLogin$(targetRedirect: string): Observable<unknown> {
    return this.authenticationService.initO8LoginFlow({ targetRedirect });
  }

  init(): Observable<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.monitorLoginError().subscribe();
      this.loadUserExtraInfo().subscribe();
      this.persistImpersonationInStorage().subscribe();
    }
    return EMPTY;
  }

  monitorLoginError(): Observable<boolean> {
    return this.authenticationService.error$.pipe(
      switchMap(() => this.getIsLoggedIn$()),
      filter((loggedIn) => !loggedIn),
      tap((e) => {
        console.warn('Authentication error', e);
        this.authenticationService.redirectToLoginPage();
      }),
    );
  }

  loadUserExtraInfo(): Observable<O8UserExtraInfo> {
    return this.getUser$().pipe(
      filter((user) => !!user?.email),
      first(),
      switchMap((user) => {
        return this.fetchUserExtraInfo$(user?.email as string);
      }),
      tap((userExtraInfo) => {
        this.userExtraInfo$.next(userExtraInfo);
      }),
      catchError((err) => {
        console.error('Could not load user profile', err);
        return EMPTY;
      }),
    );
  }

  private persistImpersonationInStorage() {
    const storageKey = `o8-impersonation-${this.window.location.hostname}`;

    const impersonatedUserInStorage = this.localStorage.getItem(storageKey);
    if (impersonatedUserInStorage) {
      const impersonatedUser = JSON.parse(impersonatedUserInStorage);
      this.impersonatedUser$.next(impersonatedUser);
    }

    return this.impersonatedUser$.pipe(
      tap((user) => {
        if (user) {
          this.localStorage.setItem(storageKey, JSON.stringify(user));
        } else {
          this.localStorage.removeItem(storageKey);
        }
      }),
    );
  }

  getUser$(): Observable<O8User | undefined> {
    const usr$ = this.authenticationService.user$;
    const permissions$ = this.authenticationService.getUserPermissions$();
    const roles$ = this.getUserRoles$();
    return combineLatest({
      usr: usr$,
      permissions: permissions$,
      roles: roles$,
      userExtraInfo: this.userExtraInfo$,
    }).pipe(
      map(({ usr, permissions, roles, userExtraInfo }) => {
        const extraInfo = userExtraInfo ?? {};
        return !usr ? undefined : ({ ...usr, permissions, roles, ...extraInfo } as unknown as O8User);
      }),
    );
  }

  getSelfOrImpersonatedUser$(): Observable<UiUserProfile | null> {
    return combineLatest({ self: this.userExtraInfo$, impersonated: this.impersonatedUser$ }).pipe(
      map(({ self, impersonated }) => {
        if (impersonated) {
          return { ...impersonated, isImpersonated: true };
        }
        return self ? { ...self, isImpersonated: false } : null;
      }),
    );
  }

  setImpersonatedUser(profile: UserProfile | null) {
    this.impersonatedUser$.next(profile);
  }

  getImpersonatedUser$(): Observable<UserProfile | null> {
    return this.impersonatedUser$.asObservable();
  }

  getAccessToken$(): Observable<string> {
    return this.authenticationService.getAccessToken$();
  }

  getUserRoles$(): Observable<APP_ROLES[]> {
    return this.authenticationService.getUserRoles$();
  }

  getUserPermissions$(): Observable<APP_PERMS[]> {
    return this.authenticationService.getUserPermissions$();
  }

  getSelfOrImpersonatedUserPermissions(): Observable<APP_PERMS[]> {
    return this.getSelfOrImpersonatedUser$().pipe(
      filter((user) => !!user),
      map((userProfile) => userProfile?.authorizations.permissions ?? []),
    );
  }

  getUserEmail$(): Observable<string> {
    return this.authenticationService.getUserEmail$();
  }

  getIsLoggedIn$(): Observable<boolean> {
    return this.authenticationService.getIsLoggedIn$();
  }

  getIsSigningIn$(): Observable<boolean> {
    return this.authenticationService.getIsSigningIn$();
  }

  logout() {
    this.userExtraInfo$.next(null);
    this.setImpersonatedUser(null);
    return this.authenticationService.logout();
  }

  fetchUserList(): Observable<UserProfile[]> {
    /* Select active users that are either staff, or advocates/contactors with a level associated*/
    const filters: O8RootQueryFilter<UserProfile> = {
      and: [
        {
          key: 'isActive',
          comparator: FilterComparators.EQUAL,
          value: true,
        },
      ],
      or: [
        {
          key: 'sfUserRoleId',
          comparator: FilterComparators.EQUAL,
          value: SF_REPRESENTATIVE_ROLE_ID,
        },
        {
          key: 'sfUserRoleId',
          comparator: FilterComparators.EQUAL,
          value: SF_STAFF_ID,
        },
      ],
    };
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ userProfileResults: { results: UserProfile[] } }>({
        query: getUsersBaseInfo,
        variables: {
          filters,
        },
        fetchPolicy: 'cache-first',
      })
      .pipe(
        map((res) => {
          return res.data.userProfileResults.results;
        }),
        catchError((err) => {
          console.error(err);
          return throwError(() => 'No user list found.');
        }),
      );
  }

  private fetchUserExtraInfo$(email: string): Observable<O8UserExtraInfo> {
    const filters: O8RootQueryFilter<UserProfile> = {
      and: [
        {
          key: 'email',
          comparator: FilterComparators.EQUAL,
          value: email,
        },
      ],
    };
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ userProfileResults: { results: UserProfile[] } }>({
        query: getUsersBaseInfo,
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data.userProfileResults.results[0];
        }),
        catchError((err) => {
          console.error(err);
          return throwError(() => 'No user profile found.');
        }),
      );
  }
}
