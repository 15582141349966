<div class="app-navbar">
  <div class="w-full contents">
    <img
      routerLink=""
      alt="origin8cares logo"
      class="navbar-img"
      src="/assets/origin8-logo-dark.png"
      e2e-id="navbar-o8-logo-link"
    />
  </div>
  <div class="navbar-spacer-vertical"></div>
  <div class="text-xl font-medium text-black-500 w-full">{{ appName }}</div>
  <div class="nav-link-item navbar-mobile-menu">
    @if (isMenuOpen) {
      <navbar-menu-dropdown (click)="closeMenu()"></navbar-menu-dropdown>
    }
    @if (!isMenuOpen) {
      <div (click)="openMenu()" class="menu-button">
        <o8-icon [icon]="menuIcon"></o8-icon>
      </div>
    }
    @if (isMenuOpen) {
      <div (click)="closeMenu()" class="menu-button">
        <o8-icon [icon]="closeIcon" (click)="closeMenu()"></o8-icon>
      </div>
    }
  </div>
</div>
