import LogRocket from 'logrocket';
import { O8TrackingModuleOptions, TrackingService } from './tracker.interface';
import { EMPTY, Subject, filter, first, from, map, of, switchMap, tap } from 'rxjs';
import type { UserService } from '../login/providers';
import { fp } from '@origin8-web/o8-utils/fp';
import { O8User } from 'common.interfaces';
import { LazyInject } from '../../shared-providers';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { encodePayloadToBase64 } from '@origin8-web/o8-utils/jwt';
import { UuidService } from '../uuid';

/* No need for injectable decorator as it is created through a factory*/
export class TrackerBrowserService implements TrackingService {
  sessionId!: string; /* Initialized in the lifetime of the app*/
  unsubscribe$ = new Subject<void>();
  hasInit = false;
  options!: O8TrackingModuleOptions & { enableSessionRecording: boolean };
  constructor(
    private lazyInject: LazyInject,
    private logRocket: typeof LogRocket,
    private router: Router,
    private uuidService: UuidService,
  ) {}

  captureException(error: Error, extra?: object): void {
    this.logRocket.captureException(error, extra);
  }

  track(
    event: string,
    data: { [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined },
  ): void {
    this.logRocket.track(event, data);
  }

  initialize(options: O8TrackingModuleOptions & { enableSessionRecording: boolean }) {
    this.options = options;
    this.options.appId
      .pipe(
        filter((id) => !!id && !this.hasInit),
        tap((id) => {
          if (this.options.enableSessionRecording) {
            this.logRocket.init(id as string, {
              console: { shouldAggregateConsoleErrors: true },
              rootHostname: 'origin8cares.com',
              shouldParseXHRBlob: true,
              network: {
                requestSanitizer: (request) => {
                  request.headers['Authorization'] = '';
                  request.headers['authorization'] = '';
                  return request;
                },
              },
            });
          }
        }),
        tap(() => {
          if (this.options.withGtm) {
            /* Lazy load GTM service to load it only in projects that need it*/
            const gtmService = this.lazyInject.getSync<GoogleTagManagerService>(GoogleTagManagerService);
            this.router.events.forEach((item) => {
              if (item instanceof NavigationEnd) {
                const gtmTag = {
                  event: 'page',
                  pageName: item.url,
                };
                gtmService.pushTag(gtmTag);
              }
            });
          }
        }),
        tap(() => {
          this.hasInit = true;
        }),
        switchMap(() => {
          if (this.options.anonymous) {
            return EMPTY;
          }
          /* Lazy load the user service only if we are in non anonymous mode*/
          return from(this.getUserService());
        }),
        switchMap((userService) => {
          return userService.getUser$().pipe(
            filter((user) => !!user?.email),
            first(),
            tap((user) => {
              const usr = user as O8User;
              this.logRocket.identify(usr.email, fp.pick(usr, 'name', 'email'));
            }),
          );
        }),
      )
      .subscribe();
  }

  getSessionId(): string {
    if (!this.sessionId) {
      this.sessionId = this.uuidService.getRandomUUID();
    }
    return this.sessionId;
  }

  getRequestId(): string {
    return this.uuidService.getRandomUUID(); /* Every request will have it's own id*/
  }

  getImpersonationToken() {
    if (this.options.anonymous) {
      return of('');
    }
    /* Lazy load the user service only if we are in non anonymous mode*/
    return from(this.getUserService()).pipe(
      switchMap((userService) => {
        return userService.getImpersonatedUser$();
      }),
      first(),
      map((user) => {
        if (!user) {
          return '';
        }
        return encodePayloadToBase64(fp.pick(user, 'email', 'authorizations'));
      }),
    );
  }

  private getUserService() {
    return this.lazyInject.get<UserService>(() => import('../login/providers').then((m) => m.UserService));
  }
}
