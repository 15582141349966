import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Origin8Icon } from '@origin8-web/core-ui/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavbarMenuDropdownComponent } from './navbar-menu-dropdown/navbar-menu-dropdown.component';
import { IconModule } from '@origin8-web/core-ui/icon';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive, NavbarMenuDropdownComponent, IconModule],
})
export class NavbarComponent {
  constructor() {}

  get appName(): string {
    return `Automated Review of Calls (ARC)`;
  }

  Origin8Icon = Origin8Icon.windows;
  menuIcon = Origin8Icon.menu;
  closeIcon = Origin8Icon.close;
  isMenuOpen = false;

  openMenu() {
    this.isMenuOpen = true;
  }
  closeMenu() {
    this.isMenuOpen = false;
  }
}
