import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { APP_PERMS } from 'common.interfaces';
import { UserService } from '../providers';
import { Observable, first, map } from 'rxjs';

export const handleCanActivatePermissions = (
  userService: UserService,
  router: Router,
  permissionsOfTheComponent: APP_PERMS[],
): Observable<boolean | UrlTree> => {
  return userService.getSelfOrImpersonatedUserPermissions().pipe(
    first(),
    map((permissions) => {
      const hasPermission = permissionsOfTheComponent.some((viewerPerm) =>
        permissions.some((perm) => perm === viewerPerm || perm === APP_PERMS.PIPELINE_READ_ALL),
      );
      if (hasPermission) {
        return true;
      } else {
        return router.parseUrl('/unauthorized');
      }
    }),
  );
};

export const canActivatePermissions = (permissions: APP_PERMS[]): (() => Observable<boolean | UrlTree>) => {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);
    return handleCanActivatePermissions(userService, router, permissions);
  };
};
