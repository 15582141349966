export type PipelineEventDataDescription = {
  value: any;
  propertyType: string;
  values: null | Array<PipelineEventDataDescription>;
  embeddedEntity: null | PipelineNestedEventData<object>;
};

export type PipelineNestedEventData<T extends object> = {
  [key in keyof T]: PipelineEventDataDescription;
};
export type PipelineEventData<T extends object> = PipelineNestedEventData<T> & {
  eventVersion: { propertyType: 'String'; value: string };
  eventSource: { propertyType: 'String'; value: string };
};

export enum PIPELINE_OPERATION_CODE {
  UPDATE = 'u',
  CREATE = 'c',
  DELETE = 'd',
}

export interface PipelineEventPayload<T extends object> {
  data: T;
  metadata: Omit<O8EventMetaToPublish, 'entityLastModified' | 'version'>;
}

export interface O8EventMetaToPublish {
  commandId?:
    | string
    | null /* If present, the Pipeline Event Writer will generate a lock mechanism until the event has passed the pipeline (successfully or not) */;
  trackId?: string /* Used so the UI can generate an event id and identify if/when the is successful when outputted outside th pipeline in a result/persisted topic*/;
  /*
   Which topic you entend to push to, this will be provided as an attribute in the pubsub result so we can filter and the UI can subscribe to it (gateway streaming will use socket room per publisherTopicId),
   it does not have to be the exact topicId as in the infra,
   it's more a topicId as a concept rather than the exact infra instance.
   Potentially, you could use the same publisherTopicId for multiple stuff that are around the same topic,
   even if in the infra side each of these stuff have a separate topic
   */
  publisherTopicId?: string;
  version: string /* Version of the event emitted format '1.0.0' */;
  source: string /* Source who emitted the event, when coming from us it's good practice to format like this: 'o8/yourSource' */;
  entityLastModified: Date;
  datatype?: string;
  op?: PIPELINE_OPERATION_CODE;
}

export type O8EventMetadata = O8EventMetaToPublish &
  Required<Pick<O8EventMetaToPublish, 'op' | 'source' | 'entityLastModified' | 'version'>> & {
    createdOn: Date;
    uuid?: string | null;
  };

export type ObjectToPublishInPipeline<T extends object = object> = T & {
  timestamp: Date;
  eventVersion?: string;
  id: string;
  eventMetadata?: O8EventMetaToPublish;
};

export enum O8_EVENT_SOURCE {
  ARC = 'o8/arc',
  PROTEC8 = 'o8/protec8',
  AB_SERVER = 'o8/abserver',
  AB_MANAGER = 'o8/abmanager',
}

export enum PUBLISHER_TOPIC_ID {
  CALL_REVIEW = 'call_review',
  RECOMMENDED_TASK_CLICK = 'recommended_task_click',
  LP_METRIC = 'lp_metric',
}

export enum O8PipelineEventStatus {
  PERSISTED = 'PERSISTED',
  DISCARDED = 'DISCARDED',
}

export enum O8PipelineEventRejectionCode {
  DUPLICATE = 'DUPLICATE',
  MISSING_VERSION = 'MISSING_VERSION',
  MISSING_EXTERNAL_IDENTIFIER = 'MISSING_EXTERNAL_IDENTIFIER',
}

export interface O8PipelineEventUpdate {
  status: O8PipelineEventStatus;
  rejectionCode: O8PipelineEventRejectionCode | null;
  eventMetadata: O8EventMetadata;
}

export type O8NormalizedPipelineEventUpdate = O8PipelineEventUpdate & {
  eventMetadata: PipelineEventDataDescription;
};

export type O8PipelineEvent<T extends object = object> = T & {
  eventMetadata: O8EventMetadata;
};

export const O8_PIPELINE_LATEST_EVENT_VERSION = '7.0.0';

export const DEFAULT_COMMAND_TTL_IN_MINUTES = 5;
